<template>
    <div v-bind:style="styles" id="page-content" style="margin-top: 5%">
      <div class="row" style="padding: 10px">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="row">
              <div class="col-lg-8">
                <div style="padding: 10px">
                  <h3>Leave</h3>
                </div>
              </div>
  
              <div class="col-lg-3">
                <span class="has-float-label" style="margin-top: 3%;">
                  <select
                    class="form-control form-input"
                    id="typeList"
                    
                  >
                  <option value="">--Select--</option>

                    <option
                      v-for="(item, index) in statusList"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <label for="clslistid">Status </label>
                </span>
              </div>
  
             
            </div>
  
            <div class="row">
              <div class="col-lg-12">
                <div style="padding: 10px">
                  <div class="restable">
                    <div class="widjetcontent" >
                      <div class="restable">
                        <div class="resrow resheader">
                          <div class="cell">S.No</div>
                          <div class="cell">From Date</div>
                            <div class="cell">To Date</div>
                            <div class="cell">No Of Days</div>
                            <div class="cell">Applied Date</div>
                            <div class="cell">Status</div>
                            <div class="cell">Action</div>
                                </div>
  
                        <div
                  class="resrow"
                  v-for="(item, index) in staffApplyLeaveRequest"
                  :key="index"
                >
                <div class="cell">{{ index + 1 }}</div>
                  <div class="cell">{{ item.from | dateFormat }}</div>
                  <div class="cell">{{ item.to | dateFormat }}</div>
                  <div class="cell">{{ item.noOfDates.length }}</div>
                  
                  <div class="cell">{{ item.createdDate | dateFormat }}</div>
                  <div class="cell" :style="item.status == 'Approved' ? 'color: #0aed06;' : 'color: red;' ">{{ item.status || '' }}</div>
                  <div class="cell" data-title="Action">
                    <b-dropdown  
                      id="dropdown-1"
                      text=""
                      right
                      :disabled="item.status != 'Pending Approval'"
                      style="margin-right: 3px"
                    >
                    <b-dropdown-item 
                       @click.prevent="confirmDelete(item)"
                       :disabled="item.status != 'Pending Approval'"
                        >Cancel</b-dropdown-item
                      >
                      
                     
                    </b-dropdown>
                    <!-- <button    
                                    class="btn round red tooltipt"
                                    :disabled="item.status != 'Pending Approval'"
                                    @click.prevent="confirmDelete(item)"
                                  >
                                    <i class="far fa-trash-alt"></i>
                                    <span class="tooltiptext">Delete</span>
                                  </button> -->
                   
                  </div>
                 
                </div>
                      </div>
                    </div>
  
                    <!-- <div class="restable" v-if="attendanceList.length == 0">
                      <div class="resrow resheader">
                        <div class="cell">S.No</div>
                        <div class="cell">Clock In Time</div>
                        <div class="cell">Clock Out Time</div>
                        <div class="cell">Created By</div>
                        <div class="cell">Action</div>
                      </div>
  
                      <div class="cell" aria-colspan="5">No data found</div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
  
  
           
          </div>
        </div>
      </div>
  
     
  
     
  
      
    </div>
  </template>
  
  <script>
  import ViService from "@/services/ViService";
  import secureUI from "../utils/secureUI";
  import { format, utcToZonedTime } from "date-fns-tz";
  
  export default {
    name: "ViStaffLeaveRequest",
    data() {
      return {
        staffApplyLeaveRequest: [],
        statusList: [
            'Approved',
            'Rejected'
        ],
        skip: 0,
        limit: 50,
        isEdit: false,
        deleteIndex: null,
        minimumDate: null,
        editIndex: 0,
        attendance: {},
        attendanceList: [],
        previousMonths: [],
        dates: [],
        selectedIndex: null,
        clockInTime: "",
        clockOutTime: "",
        selectedMonth: "",
        currentMonth: new Date().getMonth() + 1,
        months: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
      };
    },
    created() {
     
      this.getStaffLeaveApplyList()
    },
    computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
  },
    methods: {
        async getStaffLeaveApplyList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        const response = await ViService.viXGet(
          "/home/getStaffLeaveRequestList",
          userData.token
        );
        if (response.isSuccess) {
          var result = secureUI.secureGet(response.data);
          this.staffApplyLeaveRequest = result
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
      initMethod(){
        this.selectedMonth = this.months[new Date().getMonth()]
      },
      async getAttendanceList() {
        try {
          let userData = secureUI.sessionGet("user");
  
          if (!userData) {
            this.$toasted.error("Please login and do the action");
          } else {
            const response = await ViService.viXPost(
              "/user/attendance/list",
              {
                skip: this.skip,
                limit: this.limit,
                currentMonth: this.currentMonth,
              },
              userData.token
            );
  
            if (response.isSuccess) {
              this.attendanceList = secureUI.secureGet(response.data) || [];
            } else {
              console.log("in else ==> ");
            }
          }
        } catch (err) {
          console.log("in catch error ==> ", err);
        }
      },
      proceedForNewAttendance() {
        this.isEdit = false;
        this.attendance.isAbsent = false;
        this.attendance.clockOutTime = null;
        this.attendance.clockInTime = null;
        this.attendance.notes = "";
        this.$bvModal.show("modalOne");
      },
      proceedForEditAttendance(index) {
        this.isEdit = true;
        this.editIndex = index;
        this.attendance.isAbsent = this.attendanceList[index].isAbsent || false;
        this.attendance.clockOutTime = this.attendanceList[index].clockOutTime
          ? new Date(this.attendanceList[index].clockOutTime)
          : null;
        this.attendance.clockInTime = this.attendanceList[index].clockInTime
          ? new Date(this.attendanceList[index].clockInTime)
          : null;
        this.attendance.notes = this.attendanceList[index].notes || "";
  
        this.$bvModal.show("modalOne");
      },
      proceedDeletePopup(index) {
        this.deleteIndex = index;
        this.$bvModal.show("confirmationPopup");
      },
      absentChange() {
        this.attendance.clockInTime = null;
        this.attendance.clockOutTime = null;
      },
      async addAttendance() {
        try {
          let isFormValid = false;
  
          await this.$validator.validateAll("modalOne").then((result) => {
            isFormValid = result;
          });
  
          if (isFormValid) {
            let userData = secureUI.sessionGet("user");
  
            if (!userData) {
              this.$toasted.error("Please login and do the action");
              this.$router.push("/login");
            } else {
              const body = {
                clockInTime: this.attendance.clockInTime,
                clockOutTime: this.attendance.clockOutTime,
                notes: this.attendance.notes || "",
                isAbsent: this.attendance.isAbsent || false,
                type: 'Attendance Regularization',
                clockedDate: this.attendance.clockInTime
              };
  
              const response = await ViService.viXPost(
                "/home/clockin/save",
                body,
                userData.token
              );
  
              if (response.isSuccess && response.data) {
                this.attendanceList.push({ ...response.data });
                this.$bvModal.hide("modalOne");
                this.$toasted.success("Created Successfully");
              } else {
                this.$toasted.error(response.message);
              }
            }
          }
        } catch (err) {
          this.$toasted.error(
            err && err.message ? err.message : "Internal Server Error"
          );
        }
      },
      async updateAttendance() {
        try {
          let isFormValid = false;
  
          await this.$validator.validateAll("modalOne").then((result) => {
            isFormValid = result;
          });
  
          if (isFormValid) {
            let userData = secureUI.sessionGet("user");
  
            if (!userData) {
              this.$toasted.error("Please login and do the action");
              this.$router.push("/login");
            } else {
             
  
              const response = await ViService.viXPut(
                `/user/attendance/regularize`,
                this.attendance,
                userData.token
              );

              if (response.isSuccess) {
                this.attendance = {}
                this.$bvModal.hide("modalOne");
                this.$toasted.success("Regularize Updated Successfully");
                this.getAttendanceList();
              } else {
                this.$toasted.error(response.message);
              }
            }
          }
        } catch (err) {
          this.$toasted.error(
            err && err.message ? err.message : "Internal Server Error"
          );
        }
      },
      async confirmDelete(data) {
        try {
          let userData = secureUI.sessionGet("user");
  
          if (!userData) {
            this.$toasted.error("Please login and do the action");
            this.$router.push("/login");
          } else {
       

           const response = await ViService.viXPost("/home/leaveCancel/staff",data,userData.token);
            // const response = await ViService.viDelete(`/home/deleteLeaveRequest/${data._id}`,userData.token);
          
            if (response.isSuccess) {
              this.getStaffLeaveApplyList()
              this.$toasted.success("Deleted Successfully");
            } else {
              this.$toasted.error(response.message);
            }
          }
        } catch (err) {
          this.$toasted.error(
            err && err.message ? err.message : "Internal Server Error"
          );
        }
      },
      closeAttendance() {
        this.$bvModal.hide("modalOne");
      },
      closeDeletePopUp() {
        this.$bvModal.hide("modalOne");
      },
      async formDateList() {
        const currentDate = new Date();
        
        const firstDayOfMonth = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          1
        ); // Get the first day of the month
  
        this.currentMonth = currentDate.getMonth() + 1;
  
        while (currentDate >= firstDayOfMonth) {
        //  if(currentDate.getDay() == 0){
            const formattedDate = currentDate.toLocaleString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
          }); // Format the date as "Monday - May 29"
  
          const currentYear = currentDate.getFullYear();
          const currentMonth = String(currentDate.getMonth() + 1).padStart(
            2,
            "0"
          ); // Months are zero-based, so we add 1
          const currentDay = String(currentDate.getDate()).padStart(2, "0");
          
         // if(new Date(formattedDate).getDay() != 1){
          this.dates.push({
            date: formattedDate,
            createdDate: `${currentYear}-${currentMonth}-${currentDay}`,
            clockInTime: "", // Replace with the actual clockInTime value
            clockOutTime: "", // Replace with the actual clockOutTime value
          }); // Add the formatted date and other data to the array
       // }
  
          currentDate.setDate(currentDate.getDate() - 1); // Decrement the date by one day
          }
         
        //}
      },
      getTimeValuesByClockInDate(allAttendenceList, targetDate) {
  
        const temp = [...allAttendenceList]
  
        const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
        for (const obj of temp) {
  
          const createdDateTime = new Date(obj.clockInTime).setHours(0, 0, 0, 0);
  
          if (createdDateTime === targetDateTime) {
            const clockInDateTime = new Date(obj.clockInTime);
            if(obj.status == 'Leave' || obj.status == 'Pending Approval'){
              return '';
            }else{
              return clockInDateTime.toLocaleTimeString();
            }
            
          }
          // else{
          //   return '-'
          // }
        }
  
        return "";
      },
      getTimeValuesByClockOutDate(allAttendenceList, targetDate) {
        const temp = [...allAttendenceList]
  
        const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
  
        for (const obj of temp) {
          const createdDateTime = new Date(obj.clockOutTime).setHours(0, 0, 0, 0);
  
          if (createdDateTime === targetDateTime) {
            const clockOutDateTime = new Date(obj.clockOutTime);
            if(obj.status == 'Leave' || obj.status == 'Pending Approval'){
              return '';
            }else{
              return clockOutDateTime.toLocaleTimeString();
            }
           // return clockOutDateTime.toLocaleTimeString();
          }
         
        }
  
        return "";
      },
      getTimeDurationByClockInDate(allAttendenceList, targetDate) {
        const temp = [...allAttendenceList]
  
        const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
        for (const obj of temp) {
          const createdDateTime = new Date(obj.clockInTime).setHours(0, 0, 0, 0);
          if (createdDateTime === targetDateTime) {
            return new Date(obj.clockInTime);
           // return clockInDateTime.toLocaleTimeString();
          }
          // else{
          //   return '-'
          // }
        }
  
        return "";
      },
      getTimeDurationByClockOutDate(allAttendenceList, targetDate) {
        const temp = [...allAttendenceList]
        const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
  
        for (const obj of temp) {
          const createdDateTime = new Date(obj.clockOutTime).setHours(0, 0, 0, 0);
  
          if (createdDateTime === targetDateTime) {
            return new Date(obj.clockOutTime);
            //return clockOutDateTime.toLocaleTimeString();
          }
          // else{
          //   return '-'
          // }
        }
  
        return "";
      },
      getTimeValuesByDurationDate(clockInTime,clockOutTime){
       
        if(clockInTime && clockOutTime){
  
            const timeDifferenceInMilliseconds = new Date(clockOutTime) - new Date(clockInTime);
  
            // Convert milliseconds to seconds
            const seconds = Math.floor(timeDifferenceInMilliseconds / 1000);
  
            // Convert seconds to minutes
            const minutes = Math.floor(seconds / 60);
  
            // Convert minutes to hours
            const hours = Math.floor(minutes / 60);
  
            // Convert hours to days
            const days = Math.floor(hours / 24);
            return `${hours % 24}hrs : ${minutes % 60}min : ${seconds % 60}sec`
  
           // return `Time duration: ${days} days, ${hours % 24} hours, ${minutes % 60} minutes, ${seconds % 60} seconds`
  
          }else{
            return ''
          }
      },
      
      getStatusByClockInDate(allAttendenceList, targetDate) {
        let temp = [...this.attendanceList]
  
        const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
        
  
        for (const obj of temp) {
          const createdDateTime = new Date(obj.clockInTime).setHours(0, 0, 0, 0);
          if (createdDateTime === targetDateTime) {
            return obj.status;
          }
        }
  
        return "Absent";
      },
      getIsAbsentByClockInDate(allAttendenceList, targetDate) {
        const temp = [...allAttendenceList]
  
        const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
        for (const obj of temp) {
          const createdDateTime = new Date(obj.clockedDate).setHours(0, 0, 0, 0);
          if (createdDateTime === targetDateTime) {
            return obj.isAbsent;
          }
        }
      },
      openModal(data) {
        this.minimumDate = null
       // this.maxmimumDate = null
  
        let tempAttendanceList = [...this.attendanceList]
  
        if(this.getTimeValuesByClockInDate(tempAttendanceList, data.createdDate)){
         
        const targetDateTime = new Date(data.createdDate).setHours(0, 0, 0, 0);
  
        for (const obj of tempAttendanceList) {
          const createdDateTime = new Date(obj.clockInTime).setHours(0, 0, 0, 0);
          if(createdDateTime === targetDateTime) {
            this.attendance = obj
  
             var clockInDate = new Date(this.attendance.clockInTime);
             var clockInYear = clockInDate.getFullYear();
             var clockInMonth = String(clockInDate.getMonth() + 1).padStart(2, '0'); // Add 1 to month since it is zero-based
             var clockInDay = String(clockInDate.getDate()).padStart(2, '0');
             var clockInHour = String(clockInDate.getHours()).padStart(2, '0');
             var clockInMinute = String(clockInDate.getMinutes()).padStart(2, '0');
             //var clockInSecond = String(clockInDate.getSeconds()).padStart(2, '0');
  
             this.attendance.clockInTime = `${clockInYear}-${clockInMonth}-${clockInDay}T${clockInHour}:${clockInMinute}`;
             
             if(this.attendance.clockOutTime){
              
             var clockOutDate = new Date(this.attendance.clockOutTime);
             var clockOutYear = clockOutDate.getFullYear();
             var clockOutMonth = String(clockOutDate.getMonth() + 1).padStart(2, '0'); // Add 1 to month since it is zero-based
             var clockOutDay = String(clockOutDate.getDate()).padStart(2, '0');
             var clockOutHour = String(clockOutDate.getHours()).padStart(2, '0');
             var clockOutMinute = String(clockOutDate.getMinutes()).padStart(2, '0');
  
             this.attendance.clockOutTime = `${clockOutYear}-${clockOutMonth}-${clockOutDay}T${clockOutHour}:${clockOutMinute}`;

      //  /// var clockOutFormattedDate = `${year}-${month}-${day}T${'20'}:${'00'}`;
      //  this.minimumDate = this.attendance.clockOutTime
             }
            
          }
        }
  
      //   const date = new Date(data.createdDate);
      //     const year = date.getFullYear();
      //     const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to month since it is zero-based
      //     const day = String(date.getDate()).padStart(2, '0');
      //   // var hours = String(date.getHours()).padStart(2, '0');
      //   //var minutes = String(date.getMinutes()).padStart(2, '0');
      //   const clockInFormattedDate = `${year}-${month}-${day}T${'00:59'}`;
      //  /// var clockOutFormattedDate = `${year}-${month}-${day}T${'20'}:${'00'}`;
      //   this.minimumDate = clockInFormattedDate
  
        }else{
          const date = new Date(data.createdDate);
          const year = date.getFullYear();
          const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to month since it is zero-based
          const day = String(date.getDate()).padStart(2, '0');
        // var hours = String(date.getHours()).padStart(2, '0');
        //var minutes = String(date.getMinutes()).padStart(2, '0');
        const clockInFormattedDate = `${year}-${month}-${day}T${'00:59'}`;
       /// var clockOutFormattedDate = `${year}-${month}-${day}T${'20'}:${'00'}`;
        this.minimumDate = clockInFormattedDate
        }
      
       
  
        this.$bvModal.show("modalOne");
      },
      saveModal() {
        this.$bvModal.show("modalOne");
      },
      cancelModal() {
        this.$bvModal.hide("modalOne");
      },
      getPreviousMonths() {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth();
        const months = [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ];
  
        for (let i = currentMonth; i >= 0; i--) {
          this.previousMonths.push(months[i]);
        }
      },
      getSelectedMonthAttendanceList(selectedMonth) {
        this.dates = [];
  
        if (selectedMonth == this.months[new Date().getMonth()]) {
          this.formDateList();
        } else {
          // Convert month name to month index (0-based)
          const monthIndex = new Date(
            Date.parse(selectedMonth + " 1, 2023")
          ).getMonth();
  
          // Create a new Date object with the year and month
          const dateObj = new Date(2023, monthIndex);
  
          // Get the last day of the month
          const lastDate = new Date(
            dateObj.getFullYear(),
            dateObj.getMonth() + 1,
            0
          );
  
          this.currentMonth = dateObj.getMonth() + 1;
          // Get the first day of the month
          const firstDate = new Date(
            dateObj.getFullYear(),
            dateObj.getMonth(),
            1
          );
  
          const currentDate = new Date();
          const firstDayOfMonth = new Date(
            lastDate.getFullYear(),
            lastDate.getMonth(),
            1
          ); // Get the first day of the month
  
          while (lastDate >= firstDayOfMonth) {
            const formattedDate = lastDate.toLocaleString("en-US", {
              weekday: "long",
              month: "long",
              day: "numeric",
            }); // Format the date as "Monday - May 29"
  
            const currentYear = lastDate.getFullYear();
            const currentMonth = String(lastDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
            const currentDay = String(lastDate.getDate()).padStart(2, "0");
  
            this.dates.push({
              date: formattedDate,
              createdDate: `${currentYear}-${currentMonth}-${currentDay}`,
              clockInTime: "", // Replace with the actual clockInTime value
              
              clockOutTime: "", // Replace with the actual clockOutTime value
            }); // Add the formatted date and other data to the array
  
            lastDate.setDate(lastDate.getDate() - 1); // Decrement the date by one day
          }
        }
      },
    },
  };
  </script>
  
  <style scoped>
  .md_content {
    width: 50% !important;
  }
  </style>
  